import getFollowedPodcasts from 'state/YourLibrary/getFollowedPodcasts';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getNextPageKey, getReceivedFollowed } from 'state/Podcast/selectors';
import { Podcast } from 'state/Podcast/types';
import { State } from 'state/types';

const Podcasts = loadable(() => import('./Podcasts'));

type StateProps = {
  followed: Array<Podcast>;
  nextPageKey?: string | null;
  receivedFollowed: boolean;
};

export default connect(
  createStructuredSelector<State, StateProps>({
    followed: getFollowedPodcasts.selectors.selectFollowedPodcasts,
    nextPageKey: getNextPageKey,
    receivedFollowed: getReceivedFollowed,
  }),
  { requestFollowed: getFollowedPodcasts.action },
)(Podcasts);
