import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const HeaderContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    marginTop: '2rem',
  },
}));

export default HeaderContainer;
