import styled, { CSSObject } from '@emotion/styled';
import type { Theme } from 'styles/themes/default';

type Props = {
  styles?: ((a: Theme) => CSSObject) | CSSObject;
};

const Block = styled('div')<Props>(({ styles, theme }) => ({
  ...(typeof styles === 'function' ? styles(theme) : styles),
}));

export default Block;
