import getSavedStations from 'state/YourLibrary/getSavedStations';
import loadable from '@loadable/component';
import removeSavedStation from 'state/YourLibrary/removeSavedStation';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Props } from './SavedStations';
import type { State } from 'state/types';
import type { ViewWithStatics } from 'views/ViewWithStatics';

const SavedStations: ViewWithStatics = loadable(
  () => import('./SavedStations'),
);

type ConnectedProps = Omit<
  Props,
  'isSnapshot' | 'translate' | 'getSavedStations' | 'removeSavedStation'
>;

export default connect(
  createStructuredSelector<State, ConnectedProps>({
    savedStations: getSavedStations.selector,
  }),
  {
    getSavedStations: getSavedStations.action,
    removeSavedStation: removeSavedStation.action,
  },
)(SavedStations);
