import useFeature from '../useFeature';
import { getIsLoggedOut } from 'state/Session/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type PlaylistFeatures = {
  /**
   * Represents the FUPC feature flag state
   */
  freeUserPlaylistCreation: boolean;
  /**
   * Represents the FUMP feature flag state
   */
  freeUserMyPlaylist: boolean;
  /**
   * True if user is signed in, and FUPC is enabled
   */
  canCreateFreePlaylist: boolean;
  /**
   * True if user is signed in, and FUMP is enabled
   */
  canAddToMyPlaylist: boolean;
};

/**
 * Exposes playlist feature flag states and user access shorthands
 */
const usePlaylistFeatures = (): PlaylistFeatures => {
  const freeUserMyPlaylistFlag = useFeature('freeUserMyPlaylist');
  const freeUserPlaylistCreation = useFeature('freeUserPlaylistCreation');
  const internationalPlaylistRadioEnabled = useFeature(
    'internationalPlaylistRadio',
  );

  const isLoggedOut = useSelector(getIsLoggedOut);

  return useMemo(
    () => ({
      freeUserPlaylistCreation,
      freeUserMyPlaylist: freeUserMyPlaylistFlag,
      canCreateFreePlaylist:
        !isLoggedOut &&
        (freeUserPlaylistCreation || internationalPlaylistRadioEnabled),
      canAddToMyPlaylist:
        !isLoggedOut &&
        (freeUserMyPlaylistFlag || internationalPlaylistRadioEnabled),
    }),
    [
      freeUserPlaylistCreation,
      freeUserMyPlaylistFlag,
      internationalPlaylistRadioEnabled,
      isLoggedOut,
    ],
  );
};

export default usePlaylistFeatures;
