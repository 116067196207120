import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import theme from 'styles/themes/default';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { createRadio, togglePlayback } from 'shims/playbackActions';
import { PauseIcon, PlayIcon } from '../primitives';
import { PLAYER_STATE } from 'constants/playback';
import { PlayerState } from 'state/Playback/types/PlayerState';
import { STATION_TYPE, StationType } from 'constants/stationTypes';
import { useCallback } from 'react';

type Props = {
  currentlyPlaying: {
    mediaId: number;
    playedFrom: string;
    seedId: number | string;
    stationTrackId: number;
    stationType: StationType;
  };
  myMusicType: string;
  playedFrom: string;
  playingState: PlayerState;
  stationId: number; // artistId or albumId,
};

function HeaderPlayButton({
  currentlyPlaying,
  myMusicType,
  playedFrom,
  playingState,
  stationId,
}: Props) {
  const translate = useTranslate();

  const isCurrentlyPlayingStation =
    currentlyPlaying.stationType === STATION_TYPE.MY_MUSIC &&
    currentlyPlaying.seedId === stationId;

  const isCurrentlyPlaying =
    playingState === PLAYER_STATE.PLAYING && isCurrentlyPlayingStation;

  const handlePlayback = useCallback(() => {
    if (playingState === PLAYER_STATE.IDLE || !isCurrentlyPlayingStation) {
      createRadio({
        myMusicType,
        playedFrom,
        stationId,
        stationType: STATION_TYPE.MY_MUSIC,
      });
    } else {
      togglePlayback(playedFrom);
    }
  }, [
    isCurrentlyPlayingStation,
    myMusicType,
    playedFrom,
    playingState,
    stationId,
  ]);

  const Icon = isCurrentlyPlaying ? PauseIcon : PlayIcon;
  const text = isCurrentlyPlaying ? translate('Pause') : translate('Play Now');
  const customStyles =
    isCurrentlyPlaying ?
      {
        borderColor: theme.colors.red['600'],
        textColor: theme.colors.red['600'],
      }
    : {};

  return (
    <OutlinedButton
      customStyles={customStyles}
      onClick={handlePlayback}
      size="small"
    >
      <Icon />
      {text}
    </OutlinedButton>
  );
}

export default HeaderPlayButton;
