import loadable from '@loadable/component';
import { albumReceived } from 'state/Albums/actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getProfileId, getSessionId } from 'state/Session/selectors';
import type { Props } from './Albums';
import type { State } from 'state/types';

const Albums = loadable(() => import('./Albums'));

export default connect(
  createStructuredSelector<State, Pick<Props, 'profileId' | 'sessionId'>>({
    profileId: getProfileId,
    sessionId: getSessionId,
  }),
  { albumReceived },
)(Albums);
