import styled from '@emotion/styled';

const MobileMenu = styled('div')(({ theme }) => ({
  background: theme.colors.white.primary,
  borderRadius: '0.6rem',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)', // this is Elevation 1 in companion. TODO: tokenize styles when complete
  padding: '0 1.9rem',
  transform: 'translateY(1.6rem)',
  width: '100%',
}));

export default MobileMenu;
