import UserStations, { Props } from './UserStations';
import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchListenHistory } from 'state/Stations/actions';
import { flowRight } from 'lodash-es';
import {
  getListenHistoryReceived,
  getListenHistorySize,
  getRequestingListenHistory,
} from 'state/Stations/selectors';
import { getProfileId } from 'state/Session/selectors';
import { State } from 'state/types';
import type { Omit } from 'utility';

type StateProps = Pick<
  Props,
  | 'listenHistoryReceived'
  | 'listenHistorySize'
  | 'profileId'
  | 'requestingListenHistory'
>;

type DispatchProps = Pick<Props, 'fetchListenHistory'>;

type ConnectedProps = StateProps & DispatchProps;

type UserStationsType = ComponentType<Omit<Props, ConnectedProps>>;

export default flowRight(
  connect(
    createStructuredSelector<State, StateProps>({
      listenHistoryReceived: getListenHistoryReceived,
      listenHistorySize: getListenHistorySize,
      profileId: getProfileId,
      requestingListenHistory: getRequestingListenHistory,
    }),
    { fetchListenHistory },
  ),
)(UserStations) as UserStationsType;
