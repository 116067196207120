import styled from '@emotion/styled';
import { Add } from 'styles/icons';

const AddIcon = styled(Add)(({ theme }) => ({
  fill: theme.colors.gray['500'],
  height: '1rem',
  marginRight: '0.8rem',
  width: '1rem',
}));

export default AddIcon;
