import EmptyState from '../EmptyState';
import NavLink from 'components/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import UserStations from '../UserStations';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { History } from 'styles/icons';
import { Station } from 'state/Stations/types';

export type Props = {
  countryCode: string;
  stations: Array<Station>;
};

function RecentlyPlayed({ countryCode, stations = [] }: Props) {
  const translate = useTranslate();

  let title = translate(
    'Any radio, music, or podcasts you listen to will show up here',
  );
  let buttonText = translate('Browse Stations For You');
  let buttonLink = '/for-you/';

  // WW users can only see podcasts
  if (countryCode === 'WW') {
    title = translate('Any podcasts you listen to will show up here');
    buttonText = translate('Browse Podcasts');
    buttonLink = '/podcast/';
  }

  return (
    <UserStations
      emptyState={
        <EmptyState
          buttons={[
            <NavLink
              dataTest="recently-played-empty-link"
              key="recently-played-empty-link"
              to={buttonLink}
            >
              <OutlinedButton>{buttonText}</OutlinedButton>
            </NavLink>,
          ]}
          icon={History}
          noMinHeight
          subtitle={translate('Need help finding something new?')}
          title={title}
        />
      }
      hideHeaderInMobile
      recentlyPlayed
      showMobileBackIcon={false}
      stations={stations}
      title={translate('Recently Played')}
    />
  );
}

export default RecentlyPlayed;
