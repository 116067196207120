import Block from 'primitives/Layout/Block';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import P from 'primitives/Typography/BodyCopy/P';
import styled from '@emotion/styled';

type Props = {
  hideImage?: boolean;
};

const Row = styled('div')<Props>(({ hideImage = false, theme }) => {
  const playWidth = hideImage ? '4rem' : '7.6rem';

  return {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    padding: '0.4rem 0',
    width: '100%',

    [Block.toString()]: {
      /* Song Image Column */
      '&:nth-of-type(1)': {
        width: playWidth,
      },

      /* Song Information Column */
      '&:nth-of-type(2)': {
        width: `calc(50% - ${playWidth})`,

        [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
          flexBasis: `calc(95% - ${playWidth})`,
        },

        [Block.toString()]: {
          [P.toString()]: {
            '&:nth-of-type(1), &:nth-of-type(2)': {
              display: 'none',

              [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
                display: 'inline-block',
              },
            },

            display: 'inline-block',
            verticalAlign: 'middle',
          },

          a: {
            color: theme.colors.gray['400'],
            fontSize: theme.fonts.size['14'],

            [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
              fontSize: theme.fonts.size['12'],
            },
          },

          width: '100%',
        },
      },

      /* Artist Name Column */
      '&:nth-of-type(3)': {
        width: '30%',

        [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
          display: 'none',
        },

        [P.toString()]: {
          a: {
            color: theme.colors.gray['400'],
            fontSize: theme.fonts.size['14'],
          },
        },
      },

      /* Track Duration Column */
      '&:nth-of-type(4)': {
        width: '15%',

        [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
          display: 'none',
        },

        [P.toString()]: {
          color: theme.colors.gray['400'],
          fontSize: theme.fonts.size['14'],
        },
      },

      /* Dropdown Menu Column */
      '&:nth-of-type(5)': {
        button: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        paddingRight: 0,
        svg: {
          display: 'inline-block',
          verticalAlign: 'middle',
        },
        textAlign: 'right',
        width: '5%',

        [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
          marginRight: '1.5rem',
        },
      },

      paddingRight: '1rem',
    },
  };
});

export default Row;
