import LockIcon from './LockIcon';
import styled from '@emotion/styled';

const MobileMenuOption = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',

  svg: {
    marginRight: '1.5rem',
    width: '2.4rem',
  },

  [LockIcon.toString()]: {
    height: '0.9rem',
    width: '1.2rem',
  },
});

export default MobileMenuOption;
