import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  buttonOnly?: boolean;
};

const Header = styled('div')<Props>(({ buttonOnly = false, theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: buttonOnly ? 'flex-end' : 'space-between',
  marginBottom: buttonOnly ? 0 : '1.8rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    justifyContent: 'space-between',
  },
}));

export default Header;
