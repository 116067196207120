import HeaderPlayButton from '../SelectedHeader/HeaderPlayButton';
import InfiniteScroll from 'components/InfiniteScroll';
import logger, { CONTEXTS } from 'modules/Logger';
import NavLink from 'components/NavLink';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import SectionHeaderText from '../SectionHeaderText';
import SongRow from 'views/YourLibrary/SongRow';
import Truncate from 'components/Truncate';
import useFeature from 'hooks/useFeature';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { ConnectedModals } from 'state/UI/constants';
import { get } from 'lodash-es';
import { GrowlIcons } from 'components/Growls/constants';
import {
  H1,
  HeaderButtons,
  HeaderCaption,
  HeaderContainer,
  HeaderContent,
  Image,
} from '../SelectedHeader/primitives';
import { H4, Rows } from '../primitives';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { Menu } from 'components/Tooltip';
import { MY_MUSIC_SUB_TYPE } from 'state/Playback/constants';
import { Navigate } from 'state/Routing/types';
import { openModal, showNotifyGrowl } from 'state/UI/actions';
import { Params } from 'state/YourLibrary/getArtistSongs';
import { SaveDeleteComponent } from 'modules/Analytics/helpers/saveDelete';
import { slugify } from 'utils/string';
import { Track as Song } from 'state/Tracks/types';
import { STATION_TYPE, StationTypeValue } from 'constants/stationTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

export type Props = {
  artistId: number;
  getArtistSongs: (params: Params) => Promise<undefined>;
  navigate: Navigate;
  removeSongs: (ids: Array<number>) => Promise<void>;
  songs: Array<Song>;
  translate: IGetTranslateFunctionResponse;
};

function SelectedArtist({
  artistId,
  getArtistSongs,
  navigate,
  removeSongs,
  songs,
}: Props) {
  const [fetching, setFetching] = useState<boolean>(false);
  const [nextPageKey, setNextPageKey] = useState<string | null | undefined>(
    undefined,
  );
  const [hasReceivedArtistSongs, sethasReceivedArtistSongs] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const isFreeUserMyPlaylistEnabled = useFeature('freeUserMyPlaylist');
  const isInternationalPlaylistRadioEnabled = useFeature(
    'internationalPlaylistRadio',
  );

  const hasMore =
    !hasReceivedArtistSongs || (hasReceivedArtistSongs && !!nextPageKey);
  const artistName = get(songs, [0, 'artistName'], '');
  const trackIds = useMemo(() => songs.map(({ id }) => id), [songs]);

  // load more artists!
  const loadMore = useCallback(async () => {
    if (!hasMore || fetching) return;

    try {
      setFetching(true);
      const response = await getArtistSongs({ artistId, nextPageKey });
      setNextPageKey(response);
    } catch (e: any) {
      const errObj = e instanceof Error ? e : new Error(e);
      logger.error(CONTEXTS.YOUR_LIBRARY, errObj.message, {}, errObj);
    }
    setFetching(false);
    sethasReceivedArtistSongs(true);
  }, [artistId, fetching, getArtistSongs, hasMore, nextPageKey]);

  // show add to playlist modal
  const addToPlaylist = useCallback(() => {
    dispatch(
      openModal({
        id: ConnectedModals.AddToPlaylist,
        context: {
          component: SaveDeleteComponent.ListSongsOverflow,
          type: STATION_TYPE.ARTIST,
          trackIds,
        },
      }),
    );
  }, [dispatch, trackIds]);

  // remove the artist!
  const onRemove = useCallback(async () => {
    await removeSongs(trackIds);
    navigate({ path: '/your-library/artists/' });

    dispatch(
      showNotifyGrowl({
        icon: GrowlIcons.Deleted,
        title: translate('{artistName} removed from Your Library', {
          artistName,
        }),
      }),
    );
  }, [artistName, dispatch, navigate, removeSongs, trackIds, translate]);

  // load artists
  useMount(() => {
    loadMore();
  });

  // if no songs, redirect back to albums
  useEffect(() => {
    if (hasReceivedArtistSongs && songs.length === 0) {
      navigate({ path: '/your-library/artists/' });
    }
  }, [hasReceivedArtistSongs, navigate, songs]);

  if (!hasReceivedArtistSongs) return null;

  const artistUrl = `/artist/${slugify(artistName)}-${artistId}/`;

  return (
    <>
      <H4 hideHeader>
        <SectionHeaderText backLink="/your-library/artists/">
          {translate('Artists')}
        </SectionHeaderText>
      </H4>

      <HeaderContainer>
        <Image
          alt={artistName}
          aspectRatio={1}
          background
          id={artistId}
          isRound
          type={MY_MUSIC_SUB_TYPE.ARTIST as StationTypeValue}
        />

        <HeaderContent>
          <HeaderCaption>{translate('ARTIST IN YOUR LIBRARY')}</HeaderCaption>
          <NavLink to={artistUrl}>
            <H1>
              <Truncate lines={1}>{artistName}</Truncate>
            </H1>
          </NavLink>

          <HeaderButtons>
            <HeaderPlayButton
              myMusicType={MY_MUSIC_SUB_TYPE.ARTIST}
              playedFrom={PLAYED_FROM.YOUR_LIBRARY_ARTIST_SONGS}
              stationId={artistId}
            />

            <Menu>
              <Menu.List>
                {(isFreeUserMyPlaylistEnabled ||
                  isInternationalPlaylistRadioEnabled) && (
                  <Menu.Item>
                    <NavLink onClick={addToPlaylist}>
                      {translate('Add to playlist')}
                    </NavLink>
                  </Menu.Item>
                )}

                <Menu.Item>
                  <NavLink to={artistUrl}>{translate('Go to Artist')}</NavLink>
                </Menu.Item>

                <Menu.Item>
                  <NavLink onClick={onRemove}>{translate('Remove')}</NavLink>
                </Menu.Item>
              </Menu.List>
            </Menu>
          </HeaderButtons>
        </HeaderContent>
      </HeaderContainer>

      <Rows>
        <InfiniteScroll
          hasMore={hasMore}
          isFetching={fetching}
          loadMore={loadMore}
          rowHeight={74}
        >
          {songs.map(song => (
            <SongRow
              key={song.id}
              playButton={
                <PlayButton
                  additionalOpts={{ myMusicType: MY_MUSIC_SUB_TYPE.ARTIST }}
                  myMusicType={MY_MUSIC_SUB_TYPE.ARTIST}
                  playedFrom={PLAYED_FROM.YOUR_LIBRARY_ARTIST_SONGS}
                  seedId="My Music"
                  seedType="track"
                  stationId={song.artistId}
                  stationType={STATION_TYPE.MY_MUSIC}
                  trackId={song.id}
                />
              }
              song={song}
            />
          ))}
        </InfiniteScroll>
      </Rows>
    </>
  );
}

export default SelectedArtist;
