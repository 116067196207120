import CatalogImage from 'components/MediaServerImage/CatalogImage';
import styled from '@emotion/styled';

const Image = styled(CatalogImage)({
  borderRadius: '6px',
  boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, 0.15)',
  overflow: 'hidden',
});

export default Image;
