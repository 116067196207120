import countryCodes from 'constants/countryCodes';
import H5 from 'primitives/Typography/Headings/H5';
import MobileMenu from './primitives/MobileMenu';
import MobileMenuOption from './primitives/MobileMenuOption';
import MobileMenuOptionLabel from './primitives/MobileMenuOptionLabel';
import MobileMenuWrapper from './primitives/MobileMenuWrapper';
import NavRight from 'styles/icons/NavRight';
import ShouldShow from 'components/ShouldShow';
import Tab from './primitives/Tab';
import Tabs from './primitives/Tabs';
import { get } from 'lodash-es';
import { ReactNode } from 'react';

import type { ICON_TYPE, SECTION_MAP_TYPE, SectionType } from '../sectionsMap';

type Props = {
  currentSection: SectionType;
  countryCode: string;
  internationalPlaylistRadioEnabled: boolean;
  showMyMusicLibrary: boolean;
  sections: SECTION_MAP_TYPE;
  selectSection: (key: SectionType) => void;
};

const MOBILE_MENU_SECTION = 'recently-played'; // page where mobile menu will show

function Menu({
  currentSection,
  countryCode,
  internationalPlaylistRadioEnabled,
  showMyMusicLibrary,
  sections,
  selectSection,
}: Props) {
  const checkIsHidden = (section: (typeof sections)[keyof typeof sections]) => {
    return (
      // Playlists should only be displayed in countries that have the internationalPlaylistRadioEnabled feature flag
      // (US has playlists but doesn't use this feature flag) [IHRWEB-18194] - Dylan M
      (section.key === 'playlists' &&
        countryCode !== countryCodes.US &&
        !internationalPlaylistRadioEnabled) ||
      (get(section, 'needsOnDemand') && !showMyMusicLibrary)
    );
  };

  return (
    <>
      <ShouldShow shouldShow={currentSection === MOBILE_MENU_SECTION}>
        <MobileMenuWrapper>
          <MobileMenu data-test="your-library-mobile-menu">
            {Object.values(sections).reduce(
              (mappedSections: Array<ReactNode>, section) => {
                // have to reduce because we don't add the MOBILE_MENU_SECTION to menu
                const key = get(section, 'key') as SectionType;
                const Icon = get(section, 'icon') as ICON_TYPE;
                const label = get(section, 'label');
                const isHidden = checkIsHidden(section);

                if (
                  typeof section === 'object' &&
                  key !== MOBILE_MENU_SECTION &&
                  __CLIENT__
                ) {
                  mappedSections.push(
                    <MobileMenuOption
                      data-test={`your-library-${key}-mobile`}
                      isHidden={isHidden}
                      key={key}
                      onClick={() => selectSection(key)}
                    >
                      <MobileMenuOptionLabel>
                        <Icon data-test={`your-library-${key}-icon`} />

                        <H5 data-test={`your-library-${key}-label`}>{label}</H5>
                      </MobileMenuOptionLabel>

                      <NavRight />
                    </MobileMenuOption>,
                  );
                }

                return mappedSections;
              },
              [] as Array<ReactNode>,
            )}
          </MobileMenu>
        </MobileMenuWrapper>
      </ShouldShow>

      <Tabs data-test="your-library-tabs">
        {Object.values(sections).map(section => {
          if (typeof section !== 'object' || !__CLIENT__) return null;
          const Icon = get(section, 'icon');
          const label = get(section, 'label');
          const key = get(section, 'key') as SectionType;
          const isHidden = checkIsHidden(section);

          return (
            <Tab
              data-test={`your-library-${key}`}
              isHidden={isHidden}
              isSelected={currentSection === key}
              key={key}
              onClick={() => selectSection(key)}
            >
              <Icon data-test={`your-library-${key}-icon`} />

              {label}
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
}

export default Menu;
