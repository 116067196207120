import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const HeaderContent = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '2.4rem',
  width: 'calc(100% - 17.6rem)',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    width: 'calc(100% - 10rem)',
  },
}));

export default HeaderContent;
