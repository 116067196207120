import getSongs from 'state/YourLibrary/getSongs';
import resetNextPageKey from 'state/YourLibrary/resetNextPageKey';
import Songs, { Props } from './Songs';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { State } from 'state/types';
import type { ComponentType } from 'react';
import type { Omit } from 'utility';

type StateProps = Pick<Props, 'hasNextPageKey' | 'songs'>;
type DispatchProps = Pick<Props, 'getSongs' | 'resetNextPageKey'>;
type OwnProps = Omit<Props, StateProps & DispatchProps>;

export default flowRight(
  connect(
    createStructuredSelector<State, StateProps>({
      hasNextPageKey: getSongs.selectors.selectHasNextPageKey,
      songs: getSongs.selectors.selectSongs,
    }),
    {
      getSongs: getSongs.action,
      resetNextPageKey: resetNextPageKey.action,
    },
  ),
)(Songs) as ComponentType<OwnProps>;
