import BR from 'primitives/Layout/BR';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import DummyTiles from '../DummyTiles';
import factory from 'state/factory';
import InfiniteScroll from 'react-infinite-scroller';
import NavLink from 'components/NavLink';
import NewEpisodeBadge from 'components/NewEpisodeBadge/NewEpisodeBadge';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import PlaylistImage from 'components/MediaServerImage/PlaylistImage';
import Row from '../Row';
import SectionHeaderText from '../SectionHeaderText';
import ShouldShow from 'components/ShouldShow';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import useMount from 'hooks/useMount';
import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { getDropdown } from 'components/Dropdown/helpers';
import { getLiveUrl } from 'utils/url';
import { getTranslateFunction } from 'state/i18n/helpers';
import { H4, TilesWrapper } from '../primitives';
import { isPlaylist } from 'state/Playlist/helpers';
import { Station } from 'state/Stations/types';
import { STATION_TYPE } from 'constants/stationTypes';
import { TILES_IN_ROW } from '../constants';

const PlayButton = PlayerStateProxy(PlayButtonContainer);
const store = factory();
const translate = getTranslateFunction(store.getState());

export type Props = {
  emptyState: ReactNode;
  fetchListenHistory: (
    profileId: number,
    limit: number,
    offset: number,
    sortBy: string,
  ) => void;
  hideHeaderInMobile?: boolean;
  listenHistoryReceived: boolean;
  listenHistorySize: number;
  profileId: number | null;
  recentlyPlayed: boolean;
  requestingListenHistory: boolean;
  showMobileBackIcon?: boolean;
  sortBy?: string;
  stations: Array<Station>;
  title: string;
};

const LABEL_MAP = {
  artist: translate('Artist Radio'),
  collection: translate('Playlist'),
  live: translate('Live Radio'),
  playlistradio: translate('Playlist Radio'),
  podcast: translate('Podcast'),
};

function UserStations({
  emptyState,
  fetchListenHistory,
  hideHeaderInMobile,
  listenHistoryReceived,
  listenHistorySize,
  profileId,
  recentlyPlayed,
  requestingListenHistory,
  showMobileBackIcon,
  sortBy = 'LAST_PLAYED',
  stations,
  title,
}: Props) {
  const [currentLimit, setCurrentLimit] = useState(30);
  const [tiles, setTiles] = useState([...DummyTiles({})]);

  const fetchHistory = useCallback(
    async (numToFetch, offset) => {
      await fetchListenHistory(profileId!, numToFetch, offset, sortBy);
    },
    [profileId, sortBy],
  );

  // fetch history
  useMount(() => {
    if (!requestingListenHistory) {
      fetchHistory(30, 0);
    }
  });

  const loadMore = useCallback(() => {
    setCurrentLimit(currentLimit + 15);
  }, [currentLimit]);

  useEffect(() => {
    const newTiles = stations.map(station => {
      const {
        followed,
        id,
        imgWidth,
        imgUrl,
        name,
        newEpisodeCount,
        responseType,
        seedId,
        seedType,
        url: tileUrl,
      } = station;
      const liveUrl = getLiveUrl(id, name);
      const stationId = seedId || id;
      const stationType = (seedType || responseType) as keyof typeof LABEL_MAP;
      const stationUrl = tileUrl || liveUrl;
      const isRoundImage = stationType === STATION_TYPE.ARTIST;
      const isPlaylistType = isPlaylist(seedType);
      const subtitle = LABEL_MAP[stationType];

      const imageComponent = (
        <ShouldShow
          hiddenElement={
            <CatalogImage
              alt={name}
              aspectRatio={1}
              background
              height={imgWidth}
              id={String(stationId)}
              src={imgUrl}
              type={stationType}
              width={imgWidth}
            />
          }
          shouldShow={isPlaylistType}
        >
          <PlaylistImage
            alt={name}
            aspectRatio={1}
            src={imgUrl}
            width={imgWidth}
          />
        </ShouldShow>
      );

      const playButtonComponent = (
        <PlayButtonContainerPrimitive data-test="my-stations-play-button-container">
          <PlayButton
            className="play"
            deferPlay={!!tileUrl || !!liveUrl}
            seedId={stationId}
            stationId={stationId}
            stationType={stationType}
          />
        </PlayButtonContainerPrimitive>
      );

      // TODO: see if we can make shared tile to be used across sections
      return (
        <Fragment key={seedId || id}>
          <Row
            imageComponent={imageComponent}
            isRoundImage={isRoundImage}
            newEpisodeCount={newEpisodeCount}
            playButtonComponent={playButtonComponent}
            subtitleComponent={subtitle}
            title={name}
            url={stationUrl!}
          />

          <Tile
            dropdown={getDropdown(followed, profileId, station, recentlyPlayed)}
            isRoundImage={isRoundImage}
            noTileOnMobile
            subTitle={LABEL_MAP[stationType]}
            tileDelay={0}
            tilesInRow={TILES_IN_ROW}
            title={name}
            url={stationUrl!}
          >
            <NavLink to={stationUrl}>
              {playButtonComponent}
              <TilesImageWrapper
                liveTile={stationType.toLocaleLowerCase() === 'live'}
              >
                <NewEpisodeBadge newEpisodeCount={newEpisodeCount} />
                {imageComponent}
              </TilesImageWrapper>
            </NavLink>
          </Tile>
        </Fragment>
      );
    });
    setTiles(newTiles);
  }, [listenHistoryReceived, profileId, recentlyPlayed, stations]);

  const noTiles = listenHistoryReceived && tiles.length === 0;
  return (
    <>
      <H4
        data-test="your-library-user-stations-title"
        hideHeader={noTiles}
        hideInMobile={hideHeaderInMobile && noTiles}
      >
        <SectionHeaderText showMobileBackIcon={showMobileBackIcon}>
          {title}
        </SectionHeaderText>
      </H4>

      <ShouldShow shouldShow={tiles.length > 0 || !listenHistoryReceived}>
        <BR />
        <TilesWrapper>
          <InfiniteScroll
            hasMore={currentLimit < listenHistorySize}
            loadMore={loadMore}
            pageStart={0}
          >
            <Tiles noTileOnMobile tilesInRow={TILES_IN_ROW}>
              {tiles}
            </Tiles>
          </InfiniteScroll>
        </TilesWrapper>
      </ShouldShow>

      <ShouldShow shouldShow={noTiles}>{emptyState}</ShouldShow>
    </>
  );
}

export default UserStations;
