import EmptyState from '../EmptyState';
import H4 from 'primitives/Typography/Headings/H4';
import InfiniteScroll from 'components/InfiniteScroll';
import NavLink from 'components/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import SectionHeaderText from 'views/YourLibrary/SectionHeaderText';
import SongRow from 'views/YourLibrary/SongRow';
import TrackIcon from 'styles/icons/Track';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { MY_MUSIC_SUB_TYPE, STATION_TYPE } from 'state/Playback/constants';
import { Rows } from '../primitives';
import { Track as Song } from 'state/Tracks/types';
import { useCallback, useEffect, useState } from 'react';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

export type Props = {
  getSongs: (limit: number) => Promise<void>;
  hasNextPageKey: boolean;
  hasFetched: boolean;
  resetNextPageKey: () => void;
  songs: Array<Song>;
};

const BATCH_AMOUNT = 50;

function Songs({
  hasFetched = false,
  hasNextPageKey,
  getSongs,
  songs,
  resetNextPageKey,
}: Props) {
  const translate = useTranslate();

  const [hasFetchedFirstBatchOfSongs, setHasFetchedFirstBatchOfSongs] =
    useState<boolean>(hasFetched);

  useEffect(() => {
    async function fetch() {
      await getSongs(BATCH_AMOUNT);
      setHasFetchedFirstBatchOfSongs(true);
    }

    fetch();

    return () => resetNextPageKey();
  }, [getSongs, resetNextPageKey]);

  const [fetching, setFetching] = useState<boolean>(false);

  const loadMore = useCallback<() => Promise<void>>(async () => {
    if (fetching) return;
    setFetching(true);
    await getSongs(BATCH_AMOUNT);
    setFetching(false);
  }, [fetching, getSongs]);

  if (!hasFetchedFirstBatchOfSongs) return null;

  if (hasFetchedFirstBatchOfSongs && songs.length === 0) {
    return (
      <EmptyState
        buttons={[
          <NavLink
            dataTest="songs-empty-link"
            key="songs-empty-link"
            to="/artist/"
          >
            <OutlinedButton>{translate('Browse Artists')}</OutlinedButton>
          </NavLink>,
        ]}
        icon={TrackIcon}
        subtitle={translate('Need to find a new song?')}
        title={translate('Any song you save will show up here')}
      />
    );
  }

  return (
    <>
      <H4 data-test="your-library-songs-title">
        <SectionHeaderText>{translate('Songs')} (A-Z)</SectionHeaderText>
      </H4>
      <Rows>
        <InfiniteScroll
          hasMore={hasNextPageKey}
          isFetching={fetching}
          loadMore={loadMore}
          rowHeight={74}
        >
          {songs.map(song => (
            <SongRow
              key={song.id}
              playButton={
                <PlayButton
                  additionalOpts={{ myMusicType: MY_MUSIC_SUB_TYPE.TRACK }}
                  myMusicType={MY_MUSIC_SUB_TYPE.TRACK}
                  playedFrom={PLAYED_FROM.YOUR_LIBRARY_SONGS}
                  seedId="My Music"
                  seedType="track"
                  stationId="My Music"
                  stationType={STATION_TYPE.MY_MUSIC}
                  trackId={song.id}
                />
              }
              song={song}
            />
          ))}
        </InfiniteScroll>
      </Rows>
    </>
  );
}

export default Songs;
