import * as React from 'react';
import getAlbumSongs from 'state/YourLibrary/getAlbumSongs';
import removeSongs from 'state/YourLibrary/removeSongs';
import SelectedAlbum, { Props } from './SelectedAlbum';
import { AddToPlaylistContext } from 'components/AddToPlaylistModal';
import { connect } from 'react-redux';
import { ConnectedModals } from 'state/UI/constants';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getSlugId } from 'state/Routing/selectors';
import { navigate } from 'state/Routing/actions';
import { openModal } from 'state/UI/actions';
import { State } from 'state/buildInitialState';
import type { Omit } from 'utility';

type StateProps = Pick<Props, 'albumId' | 'songs'>;

export default flowRight(
  connect(
    createStructuredSelector<State, { albumId: string | number }, StateProps>({
      albumId: getSlugId,
      songs: getAlbumSongs.selectors.selectAlbumSongs,
    }),
    {
      getAlbumSongs: getAlbumSongs.action,
      navigate,
      removeSongs: removeSongs.action,
      openAddToPlaylist: (context: AddToPlaylistContext) =>
        openModal({
          id: ConnectedModals.AddToPlaylist,
          context,
        }),
    },
  ),
)(SelectedAlbum) as React.ComponentType<Omit<Props, StateProps>>;
