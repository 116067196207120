import CatalogImage from 'components/MediaServerImage/CatalogImage';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  isRound?: boolean;
};

const Image = styled(CatalogImage)<Props>(({ isRound = false, theme }) => ({
  borderRadius: isRound ? '50%' : '0.6rem',
  boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, 0.15)',
  overflow: 'hidden',
  width: '17.6rem',

  '.background': {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    width: '10rem',
  },
}));

export default Image;
