import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Tabs = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '7rem',
  position: 'sticky',
  top: '8.8rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    display: 'none',
  },
}));

export default Tabs;
