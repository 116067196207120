import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Author = styled('span')(({ theme }) => ({
  ...theme.mixins.ellipis,
  [mediaQueryBuilder(theme.mediaQueries.min.width['599'])]: {
    maxWidth: '50%',
  },
}));

export default Author;
