import Block from 'primitives/Layout/Block';
import NavLink from 'components/NavLink';
import P from 'primitives/Typography/BodyCopy/P';
import PlayButton from 'components/Player/PlayButtonContainer';
import ShouldShow from 'components/ShouldShow';
import Truncate from 'components/Truncate';
import useFeature from 'hooks/useFeature';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { ConnectedModals } from 'state/UI/constants';
import { formatSongDuration } from 'utils/time/dates';
import { GrowlIcons } from 'components/Growls/constants';
import { Image, NoImagePlayButton, PlayButtonWrapper, Row } from './primitives';
import { Menu } from 'components/Tooltip';
import { MenuButton } from '../primitives';
import { openModal, showNotifyGrowl } from 'state/UI/actions';
import { ReactElement, useCallback } from 'react';
import { SaveDeleteComponent } from 'modules/Analytics/helpers/saveDelete';
import { Track as Song } from 'state/Tracks/types';
import { STATION_TYPE } from 'constants/stationTypes';
import { useDispatch } from 'react-redux';

type Props = {
  hideImage?: boolean;
  playButton: ReactElement<typeof PlayButton>;
  removeSongs: (ids: Array<number>) => Promise<void>;
  song: Song;
};

function SongRow({ hideImage, playButton, removeSongs, song }: Props) {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const isFreeUserMyPlaylistEnabled = useFeature('freeUserMyPlaylist');
  const isInternationalPlaylistRadioEnabled = useFeature(
    'internationalPlaylistRadio',
  );

  const addToPlaylist = useCallback<() => void>(() => {
    dispatch(
      openModal({
        id: ConnectedModals.AddToPlaylist,
        context: {
          component: SaveDeleteComponent.ListSongsOverflow,
          trackIds: [song.id],
          type: STATION_TYPE.ARTIST,
        },
      }),
    );
  }, [dispatch, song.id]);

  const removeSong = useCallback<() => Promise<void>>(
    () =>
      removeSongs([song.id]).then(() => {
        dispatch(
          showNotifyGrowl({
            title: translate('{songTitle} removed from Your Library', {
              songTitle: song.title || '',
            }),
            icon: GrowlIcons.Deleted,
          }),
        );
      }),
    [removeSongs, song.id, song.title, translate],
  );

  return (
    <Row data-test="track-row" hideImage={hideImage} key={song.id}>
      <Block>
        <ShouldShow
          hiddenElement={
            <NoImagePlayButton>
              <PlayButtonWrapper hideHover>{playButton}</PlayButtonWrapper>
            </NoImagePlayButton>
          }
          shouldShow={!hideImage}
        >
          <Image
            alt="Track Artwork"
            aspectRatio={1}
            data-test="yourlibrarysongs-image"
            id={song.id}
            type="track"
          >
            <PlayButtonWrapper>{playButton}</PlayButtonWrapper>
          </Image>
        </ShouldShow>
      </Block>
      <Block data-test="yourlibrarysongs-songname">
        <P>
          <NavLink to={song.urls.song}>
            <Truncate>{song.trackTitle}</Truncate>
          </NavLink>
        </P>
        <Block>
          <P>
            <NavLink dataTest="yourlibrarysongs-artist" to={song.urls.artist}>
              <Truncate>{song.artistName}</Truncate>
            </NavLink>
          </P>
          <P>&nbsp;&#183;&nbsp;</P>
          <P>
            <NavLink to={song.urls.album}>
              <Truncate>{song.albumTitle}</Truncate>
            </NavLink>
          </P>
        </Block>
      </Block>
      <Block data-test="yourlibrarysongs-artistname">
        <P>
          <NavLink to={song.urls.artist}>
            <Truncate>{song.artistName}</Truncate>
          </NavLink>
        </P>
      </Block>
      <Block data-test="yourlibrarysongs-duration">
        <P>{formatSongDuration(song.duration)}</P>
      </Block>
      <Block>
        <Menu>
          <Menu.List>
            {(isFreeUserMyPlaylistEnabled ||
              isInternationalPlaylistRadioEnabled) && (
              <Menu.Item>
                <MenuButton onClick={addToPlaylist} type="button">
                  {translate('Add to Playlist')}
                </MenuButton>
              </Menu.Item>
            )}
            <Menu.Item>
              <NavLink to={song.urls.artist}>
                {translate('Go to Artist')}
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <NavLink to={song.urls.album}>{translate('Go to Album')}</NavLink>
            </Menu.Item>
            <Menu.Item>
              <MenuButton onClick={removeSong} type="button">
                {translate('Remove')}
              </MenuButton>
            </Menu.Item>
          </Menu.List>
        </Menu>
      </Block>
    </Row>
  );
}

export default SongRow;
