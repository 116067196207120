import RecentlyPlayed, { Props } from './RecentlyPlayed';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCountryCode } from 'state/Config/selectors';
import { getRecentlyPlayedStations } from 'state/Stations/selectors';
import { State } from 'state/buildInitialState';

type ConnectedProps = {
  countryCode: Props['countryCode'];
  stations: Props['stations'];
};

type StateProps = Pick<ConnectedProps, 'stations' | 'countryCode'>;

export default connect(
  createStructuredSelector<State, StateProps>({
    countryCode: getCountryCode,
    stations: getRecentlyPlayedStations,
  }),
)(RecentlyPlayed);
