import AlbumIcon from 'styles/icons/Album';
import ArtistsIcon from 'styles/icons/Artists';
import factory from 'state/factory';
import HistoryIcon from 'styles/icons/History';
import LiveRadioIcon from 'styles/icons/LiveRadio';
import MicIcon from 'styles/icons/Mic';
import PlaylistsIcon from 'styles/icons/Playlists';
import TrackIcon from 'styles/icons/Track';
import { getTranslateFunction } from 'state/i18n/helpers';

const store = factory();
const translate = getTranslateFunction(store.getState());

export type SectionType =
  | 'recently-played'
  | 'saved-stations'
  | 'podcasts'
  | 'playlists'
  | 'albums'
  | 'artists'
  | 'songs';

export type ICON_TYPE =
  | typeof AlbumIcon
  | typeof ArtistsIcon
  | typeof HistoryIcon
  | typeof LiveRadioIcon
  | typeof MicIcon
  | typeof PlaylistsIcon
  | typeof TrackIcon;

export type SECTION_MAP_TYPE = {
  [Key in SectionType]: {
    canHaveInternationalPlaylistRadio: boolean;
    fullMobileHeight: boolean;
    icon: ICON_TYPE;
    key: string;
    label: string;
    needsAuth: boolean;
    needsOnDemand: boolean;
    upsellText?: string;
  };
};

export const SECTIONS_MAP: SECTION_MAP_TYPE = {
  'recently-played': {
    canHaveInternationalPlaylistRadio: false,
    fullMobileHeight: false,
    icon: HistoryIcon,
    key: 'recently-played',
    label: translate('Recently Played'),
    needsAuth: false,
    needsOnDemand: false,
  },
  'saved-stations': {
    canHaveInternationalPlaylistRadio: false,
    fullMobileHeight: true,
    icon: LiveRadioIcon,
    key: 'saved-stations',
    label: translate('Followed Stations'),
    needsAuth: false,
    needsOnDemand: false,
  },
  podcasts: {
    canHaveInternationalPlaylistRadio: false,
    fullMobileHeight: true,
    icon: MicIcon,
    key: 'podcasts',
    label: translate('Podcasts'),
    needsAuth: false,
    needsOnDemand: false,
  },
  playlists: {
    canHaveInternationalPlaylistRadio: true,
    fullMobileHeight: true,
    icon: PlaylistsIcon,
    key: 'playlists',
    label: translate('Playlists'),
    needsAuth: true,
    needsOnDemand: false,
  },
  artists: {
    canHaveInternationalPlaylistRadio: false,
    fullMobileHeight: true,
    icon: ArtistsIcon,
    key: 'artists',
    label: translate('Artists'),
    needsAuth: false,
    needsOnDemand: true,
    upsellText: 'music',
  },
  albums: {
    canHaveInternationalPlaylistRadio: false,
    fullMobileHeight: true,
    icon: AlbumIcon,
    key: 'albums',
    label: translate('Albums'),
    needsAuth: false,
    needsOnDemand: true,
    upsellText: 'albums',
  },
  songs: {
    canHaveInternationalPlaylistRadio: false,
    fullMobileHeight: true,
    icon: TrackIcon,
    key: 'songs',
    label: translate('Songs'),
    needsAuth: false,
    needsOnDemand: true,
    upsellText: 'songs',
  },
};
