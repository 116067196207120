import styled from '@emotion/styled';
import { TargetButton } from 'components/Tooltip/primitives';

const HeaderButtons = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  marginTop: '0.5rem',

  [TargetButton.toString()]: {
    display: 'flex',
    marginLeft: '1rem',
  },
});

export default HeaderButtons;
