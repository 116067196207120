import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const MobileMenuWrapper = styled('div')(({ theme }) => ({
  background: theme.colors.gradients.sunrise,
  display: 'none',
  marginBottom: '1.5rem',
  paddingLeft: '1.6rem',
  paddingRight: '1.6rem',
  width: '100vw',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    display: 'flex',
  },
}));

export default MobileMenuWrapper;
