import Menu from './Menu';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCountryCode } from 'state/Config/selectors';
import {
  getInternationalPlaylistRadioEnabled,
  getOnDemandEnabled,
} from 'state/Features/selectors';
import { getIsAnonymous } from 'state/Session/selectors';
import { showMyMusicLibrarySelector } from 'state/Entitlements/selectors';
import type { State } from 'state/types';

export default connect(
  createStructuredSelector<
    State,
    {
      internationalPlaylistRadioEnabled: boolean;
      isAnonymous: boolean;
      onDemandEnabled: boolean;
      showMyMusicLibrary: boolean;
      countryCode: string;
    }
  >({
    internationalPlaylistRadioEnabled: getInternationalPlaylistRadioEnabled,
    isAnonymous: getIsAnonymous,
    onDemandEnabled: getOnDemandEnabled,
    showMyMusicLibrary: showMyMusicLibrarySelector,
    countryCode: getCountryCode,
  }),
)(Menu);
