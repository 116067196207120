import * as React from 'react';
import getArtistSongs from 'state/YourLibrary/getArtistSongs';
import removeSongs from 'state/YourLibrary/removeSongs';
import SelectedArtist, { Props } from './SelectedArtist';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getSlugId } from 'state/Routing/selectors';
import { navigate } from 'state/Routing/actions';
import { Track as Song } from 'state/Tracks/types';
import { State } from 'state/buildInitialState';
import type { Omit } from 'utility';

type StateProps = {
  artistId: string;
  songs: Array<Song>;
};

export default flowRight(
  connect(
    createStructuredSelector<State, StateProps>({
      artistId: getSlugId,
      songs: getArtistSongs.selectors.selectArtistSongs,
    }),
    {
      getArtistSongs: getArtistSongs.action,
      navigate,
      removeSongs: removeSongs.action,
    },
  ),
)(SelectedArtist) as React.ComponentType<Omit<Props, StateProps>>;
