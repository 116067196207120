import styled from '@emotion/styled';

type Props = {
  isHidden: boolean;
  isSelected: boolean;
};

const Tab = styled('div')<Props>(
  ({ isHidden = false, isSelected = false, theme }) => ({
    alignItems: 'center',
    color: isSelected ? theme.colors.red['600'] : theme.colors.gray['500'],
    cursor: 'pointer',
    display: isHidden ? 'none' : 'flex',
    flexDirection: 'column',
    fontSize: '1.3rem',
    justifyContent: 'center',
    lineHeight: '2rem',
    margin: '1rem 0',
    position: 'relative',
    textAlign: 'center',
    transition: 'all 0.4s ease-in-out',

    svg: {
      marginBottom: '0.7rem',

      '> *': {
        fill: isSelected ? theme.colors.red['600'] : theme.colors.gray['500'],
        transition: 'all 0.4s ease-in-out',
      },
    },

    '&:hover': {
      color: theme.colors.red['600'],

      'svg > *': {
        fill: theme.colors.red['600'],
      },
    },
  }),
);

export default Tab;
