import loadable from '@loadable/component';
import { artistReceived } from 'state/Artists/actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getProfileId, getSessionId } from 'state/Session/selectors';
import type { Props } from './Artists';
import type { State } from 'state/types';

const Artists = loadable(() => import('./Artists'));

type SelectorValues = {
  profileId: Props['profileId'];
  sessionId: Props['sessionId'];
};

export default connect(
  createStructuredSelector<State, SelectorValues>({
    profileId: getProfileId,
    sessionId: getSessionId,
  }),
  { artistReceived },
)(Artists);
