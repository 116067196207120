import HeaderPlayButton from '../SelectedHeader/HeaderPlayButton';
import InfiniteScroll from 'components/InfiniteScroll';
import logger, { CONTEXTS } from 'modules/Logger';
import NavLink from 'components/NavLink';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import SectionHeaderText from '../SectionHeaderText';
import SongRow from 'views/YourLibrary/SongRow';
import Truncate from 'components/Truncate';
import useFeature from 'hooks/useFeature';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { AddToPlaylistContext } from 'components/AddToPlaylistModal';
import { GrowlIcons } from 'components/Growls/constants';
import {
  H1,
  HeaderButtons,
  HeaderCaption,
  HeaderContainer,
  HeaderContent,
  Image,
} from '../SelectedHeader/primitives';
import { H4, Rows } from '../primitives';
import { Menu } from 'components/Tooltip';
import { MY_MUSIC_SUB_TYPE } from 'state/Playback/constants';
import { Navigate } from 'state/Routing/types';
import {
  SaveDeleteComponent,
  SaveDeleteView,
} from 'modules/Analytics/helpers/saveDelete';
import { showNotifyGrowl } from 'state/UI/actions';
import { STATION_TYPE, StationTypeValue } from 'constants/stationTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { Params } from 'state/YourLibrary/getAlbumSongs';
import type { Track } from 'state/Tracks/types';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

export type Props = {
  albumId: number;
  getAlbumSongs: (params: Params) => Promise<string | null>;
  navigate: Navigate;
  openAddToPlaylist: (context: AddToPlaylistContext) => void;
  removeSongs: (ids: Array<number>) => Promise<void>;
  songs: Array<Track>;
};

function SelectedAlbum({
  albumId,
  getAlbumSongs,
  navigate,
  openAddToPlaylist,
  removeSongs,
  songs,
}: Props) {
  const [fetching, setFetching] = useState<boolean>(false);
  const [nextPageKey, setNextPageKey] = useState<string | null | undefined>(
    undefined,
  );
  const [hasReceivedAlbumSongs, setHasReceivedAlbumSongs] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const isFreeUserMyPlaylistEnabled = useFeature('freeUserMyPlaylist');
  const isInternationalPlaylistRadioEnabled = useFeature(
    'internationalPlaylistRadio',
  );

  const hasMore =
    !hasReceivedAlbumSongs || (hasReceivedAlbumSongs && !!nextPageKey);
  const trackIds = useMemo(() => songs.map(({ id }) => id), [songs]);
  const albumName = songs?.[0]?.albumName ?? '';
  const artistName = songs?.[0]?.artistName ?? '';
  const artistUrl = songs?.[0]?.urls?.artist ?? '';
  const albumUrl = songs?.[0]?.urls?.album ?? '';

  // load more artists!
  const loadMore = useCallback(async () => {
    if (!hasMore || fetching) return;

    try {
      setFetching(true);
      const response = await getAlbumSongs({ albumId, nextPageKey });
      setNextPageKey(response);
    } catch (e: any) {
      const errObj = e instanceof Error ? e : new Error(e);
      logger.error(CONTEXTS.YOUR_LIBRARY, errObj.message, {}, errObj);
    }
    setFetching(false);
    setHasReceivedAlbumSongs(true);
  }, [albumId, fetching, getAlbumSongs, hasMore, nextPageKey]);

  // show add to playlist modal
  const addToPlaylist = useCallback(() => {
    openAddToPlaylist({
      trackIds,
      view: SaveDeleteView.AlbumProfile,
      component: SaveDeleteComponent.Overflow,
      type: STATION_TYPE.ARTIST,
    });
  }, [openAddToPlaylist, trackIds]);

  // remove the artist!
  const onRemove = useCallback(async () => {
    await removeSongs(trackIds);
    navigate({ path: '/your-library/albums/' });

    dispatch(
      showNotifyGrowl({
        title: translate('{albumName} removed from Your Library', {
          albumName,
        }),
        icon: GrowlIcons.Deleted,
      }),
    );
  }, [albumName, dispatch, navigate, removeSongs, trackIds, translate]);

  // load albums
  useMount(() => {
    loadMore();
  });

  // if no songs, redirect back to albums
  useEffect(() => {
    if (hasReceivedAlbumSongs && songs.length === 0) {
      navigate({ path: '/your-library/albums/' });
    }
  }, [hasReceivedAlbumSongs, navigate, songs]);

  if (!hasReceivedAlbumSongs) return null;

  return (
    <>
      <H4 hideHeader>
        <SectionHeaderText backLink="/your-library/albums/">
          {translate('Albums')}
        </SectionHeaderText>
      </H4>

      <HeaderContainer>
        <Image
          alt={artistName}
          aspectRatio={1}
          background
          id={albumId}
          type={MY_MUSIC_SUB_TYPE.ALBUM as StationTypeValue}
        />

        <HeaderContent>
          <HeaderCaption>
            <NavLink to={artistUrl}>{artistName}</NavLink>
          </HeaderCaption>

          <NavLink to={albumUrl}>
            <H1>
              <Truncate lines={1}>{albumName}</Truncate>
            </H1>
          </NavLink>

          <HeaderButtons>
            <HeaderPlayButton
              myMusicType={MY_MUSIC_SUB_TYPE.ALBUM}
              playedFrom={PLAYED_FROM.YOUR_LIBRARY_ALBUM_SONGS}
              stationId={albumId}
            />

            <Menu>
              <Menu.List>
                {(isFreeUserMyPlaylistEnabled ||
                  isInternationalPlaylistRadioEnabled) && (
                  <Menu.Item>
                    <NavLink onClick={addToPlaylist}>
                      {translate('Add to Playlist')}
                    </NavLink>
                  </Menu.Item>
                )}

                <Menu.Item>
                  <NavLink to={artistUrl}>{translate('Go to Artist')}</NavLink>
                </Menu.Item>

                <Menu.Item>
                  <NavLink to={albumUrl}>{translate('Go to Album')}</NavLink>
                </Menu.Item>

                <Menu.Item>
                  <NavLink onClick={onRemove}>{translate('Remove')}</NavLink>
                </Menu.Item>
              </Menu.List>
            </Menu>
          </HeaderButtons>
        </HeaderContent>
      </HeaderContainer>

      <Rows>
        <InfiniteScroll
          hasMore={hasMore}
          isFetching={fetching}
          loadMore={loadMore}
          rowHeight={74}
        >
          {songs.map(song => (
            <SongRow
              hideImage
              key={song.id}
              playButton={
                <PlayButton
                  additionalOpts={{ myMusicType: MY_MUSIC_SUB_TYPE.ALBUM }}
                  myMusicType={MY_MUSIC_SUB_TYPE.ALBUM}
                  playedFrom={PLAYED_FROM.YOUR_LIBRARY_ALBUM_SONGS}
                  seedId="My Music"
                  seedType="track"
                  stationId={song.albumId}
                  stationType={STATION_TYPE.MY_MUSIC}
                  trackId={song.id}
                />
              }
              song={song}
            />
          ))}
        </InfiniteScroll>
      </Rows>
    </>
  );
}

export default SelectedAlbum;
