import H1Component from 'primitives/Typography/Headings/H1';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const H1 = styled(H1Component)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    fontSize: theme.fonts.size['20'],
    lineHeight: '2.6rem',
  },
}));

export default H1;
