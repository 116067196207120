import styled from '@emotion/styled';

type Props = {
  isHidden: boolean;
};

const MobileMenuOption = styled('div')<Props>(
  ({ isHidden = false, theme }) => ({
    color: theme.colors.gray['600'],
    cursor: 'pointer',
    display: isHidden ? 'none' : 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '2.6rem 0',

    path: {
      fill: theme.colors.gray['400'],
    },
  }),
);

export default MobileMenuOption;
