import PlayButtonWrapper from './PlayButtonWrapper';
import styled from '@emotion/styled';

const NoImagePlayButton = styled('div')(({ theme }) => ({
  [PlayButtonWrapper.toString()]: {
    '& > button': {
      backgroundColor: theme.colors.transparent.primary,
      borderColor: theme.colors.gray['300'],
      height: '3rem',
      opacity: 1,
      position: 'relative',
      transition: 'background-color 0.4s ease-in-out',
      visibility: 'visible',
      width: '3rem',
      zIndex: 1,

      svg: {
        fill: theme.colors.gray['600'],
      },
    },

    '&:hover > button': {
      backgroundColor: theme.colors.gray['600'],
      borderColor: theme.colors.gray['600'],

      svg: {
        fill: theme.colors.white.primary,
      },
    },
  },

  height: '10%',
}));

export default NoImagePlayButton;
