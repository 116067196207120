import Icon from 'styles/icons/Lock';
import styled from '@emotion/styled';

const LockIcon = styled(Icon)(({ theme }) => ({
  height: '0.9rem',
  left: '1.3rem',
  position: 'absolute',
  top: 0,
  width: '1.2rem',

  path: {
    fill: theme.colors.gray['400'],
  },
}));

export default LockIcon;
