import styled from '@emotion/styled';
import { Pause } from 'styles/icons';

const PauseIcon = styled(Pause)(({ theme }) => ({
  fill: theme.colors.red['600'],
  height: '1rem',
  marginRight: '1rem',
}));

export default PauseIcon;
