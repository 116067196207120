import composeRequest, {
  authHeaders,
  method,
  query,
  urlTagged,
} from 'api/helpers';
import nameSorter from 'utils/nameSorter';
import { CONTEXTS } from 'modules/Logger';
import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { getAmpUrl } from 'state/Config/selectors';
import { getCredentials, getIsAuthenticated } from 'state/Session/selectors';
import { getPodcasts, getReceivedFollowed } from 'state/Podcast/selectors';
import { Podcast } from 'state/Podcast/types';
import { receivedFollowed } from 'state/Podcast/actions';
import { Selector, Thunk } from 'state/types';

const constant = 'YOUR_LIBRARY:GET_FOLLOWED_PODCASTS';

export type Params = {
  limit?: number;
  pageKey?: string | null;
};

function service({
  limit,
  pageKey,
}: Params): Thunk<Promise<{ nextPageKey?: string; podcasts: any }>> {
  return async function thunk(_dispatch, getState, { transport }) {
    const state = getState();
    const { profileId, sessionId } = getCredentials(state);
    const ampUrl = getAmpUrl(state);

    const response = await transport(
      composeRequest(
        method('get'),
        urlTagged`${{ ampUrl }}/api/v3/podcast/follows`,
        authHeaders(profileId || '', sessionId || ''),
        // no clue why the param is different for this call ... AMP, amirite?
        query({ withNewEpisodeCounts: true, limit, pageKey, sortBy: 'TITLE' }),
      )(),
    );

    const { data, links } = response.data;

    return {
      nextPageKey: links ? links.next : undefined,
      podcasts: data,
    };
  };
}

function action({
  limit,
  pageKey,
}: Params): Thunk<Promise<{ nextPageKey?: any; podcasts?: any }>> {
  return async function thunk(dispatch, getState, { logger }) {
    const state = getState();
    const isAuthenticated = getIsAuthenticated(state);

    if ((getReceivedFollowed(state) || !isAuthenticated) && !pageKey) return {};

    try {
      const { nextPageKey, podcasts } = await dispatch(
        service({
          limit,
          pageKey,
        }),
      );

      dispatch(receivedFollowed({ nextPageKey, podcasts }));

      return {
        nextPageKey,
        podcasts,
      };
    } catch (error: any) {
      const errObj = error instanceof Error ? error : new Error(error);
      logger.error([CONTEXTS.REDUX, constant], errObj.message, {}, errObj);
      throw errObj;
    }
  };
}

const selectFollowedPodcasts: Selector<Array<Podcast>> = createSelector(
  getPodcasts,
  podcasts =>
    Object.values(podcasts)
      .filter(podcast => get(podcast, 'followed'))
      .sort((stationA, stationB) => nameSorter(stationA, stationB, 'title')) ||
    [],
);

export default {
  action,
  constant,
  selectors: {
    selectFollowedPodcasts,
  },
};
